///////////////////////////////////////////////////
//
// TOP BAR
//
///////////////////////////////////////////////////

.topBar {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 203;

    @include media($tablet) {
        height: 40px;
    }

    @include media($desktop) {
        height: 40px;
    }

    @media screen and (min-width: 1440px) {
        height: 60px;
    }

    &_wrapper {
        @include clearfix;

        @include media($tablet) {
            position: fixed;
            right: 0;
            top: 0;
        }

        &_link {
            display: block;
            float: left;
            font-size: 14px;
            line-height: 40px;
            text-decoration: none;
            text-transform: uppercase;
            white-space: nowrap;
            font-weight: bold;

            @include media($mobile-only) {
                text-align: center;
                width: 50%;
            }

            @include media($tablet) {
                padding: 0 15px;
            }

            @include media($desktop) {
                font-size: 15px;
                line-height: 34px;
                padding: 4px 20px 0 20px;
            }

            @media screen and (min-width: 1440px) {
                font-size: 16px;
                line-height: 54px;
                padding: 3px 20px 0 20px;
            }

            &-secondary {
                background: transparentize($black, 0.9);
                transition: background 0.3s;
                font-weight: bold;

                @include media($desktop) {
                    font-size: 17px;
                    line-height: 40px;
                    padding: 0 40px;
                }

                @media screen and (min-width: 1440px) {
                    font-size: 23px;
                    line-height: 60px;
                    padding: 0 40px;
                }

                &:hover {
                    background: transparentize($black, 0.8);                   
                }
            }

            &-default {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &-socialHeader {
        .footer_social_icon {
            @include media($mobile-only) {
                display: none;
            }
        }
    }

    &-socialFooter {
        .footer_social_icon {
            display: none;
        }
    }
}
