///////////////////////////////////////////////////
//
// MENU SCREEN
// Prevents mobile Safari from scrolling container.
//
///////////////////////////////////////////////////

.headerScreen {
    // +option: background = color_background_secondary
    transition: all 0.2s;
    height: 0px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -99;

    .body-headerActive & {
        height: 99999px;
        opacity: 1;
        pointer-events: all;
        z-index: 101;
    }
}
