// *************************************
//
//  Helpers
//  -> These are project-specific Extends, 
//  Functions, Mixins, Animations, Grid Classes
//
//  --- Edit the following on this sheet: --- //
//
// *************************************

// -------------------------------------
//  Extends
// -------------------------------------


// -------------------------------------
//  Functions
// -------------------------------------
//Numbers must be between 1 and 10;
//LIGHTEN: Numbers from -10 to -1 will darken the color; 
//DARKEN: Numbers from 1 to 10 will lighten the color;
//To use, add this function to your scss: e.g., background-color: custom-color(red, 2);
@function custom-color($color, $number) {
	@if($number < 0 and $number > -11){
		$amount: ($number * -1) * 10%;
		@return darken($color, $amount);
	} @elseif ($number > 0 and $number < 11) {
		$amount: ($number) * 10%;
		@return lighten($color, $amount);
	} @else {
		@return $color;
	}
}


// -------------------------------------
//  Mixins
// -------------------------------------

@mixin line-behind {
	height: 12px;
	margin: 40px 0;

	border-bottom: 1px solid darken(white, 10%);
	text-align: center;

	h2, h4 {
		display: inline;
		padding: 0 10px;
		background: white;
	}

	h2 {
		font-size: 36px;
		line-height: 1.5;
	}
}

.line-behind-text {
	@include line-behind;
}

//Linear gradient for all other all browsers EXCEPT IE
@mixin gradient($start, $color-stops...){
	@if ($start != "") {
		$start: top;
	}

	background-color: transparent;
	background-image: -webkit-linear-gradient($start, $color-stops);
	background-image: -moz-linear-gradient($start, $color-stops);
	background-image: -o-linear-gradient($start, $color-stops);
	background-image: -ms-linear-gradient($start, $color-stops);
	background: linear-gradient(to $start,$color-stops);
}

// Linear gradient mixin that should work with IE8 and below -- add this mixin ONLY to ie-specific stylesheet. Use ColorZilla svg code for IE9.
@mixin gradient-ie($color1, $color2) {
	$first-ie: ie-hex-str($color1);
	$second-ie: ie-hex-str($color2);
	// background-color: mix($color1, $color2)!important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$first-ie}', endColorstr='#{$second-ie}', gradientType='0' GradientType =1)!important;
	-ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$first-ie}', endColorstr='#{$second-ie}', gradientType='0' GradientType =1)!important;
	width: 100%;
	height: 100%;
}

@mixin triangle-sprite {
  width: 0; 
  height: 0; 
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-right:100px solid $white; 
}



// -------------------------------------
//  Animations
// -------------------------------------

// -------------------------------------
//  Grid
// -------------------------------------
	.single-post .grid-wrapper,
	.blog .grid-wrapper,
	.category .grid-wrapper,
	.archive .grid-wrapper {
		@include outer-container;

		article {
			@include span-columns(7);
			@include shift(.5);

		}

		.sidebar {
			@include span-columns(3);
			@include shift(.5);
			@include omega();
		}

		.blog-pagination {
			clear: both;
		}

	}


