///////////////////////////////////////////////////
//
// NEWSLETTER
//
///////////////////////////////////////////////////

.moduleWrapper .newsletter {
	padding: 15px;
	position: relative;

    @include media($tablet-only) {
        text-align: center;
    }

    @include media($desktop) {
        padding: 70px 15px 20px 15px;
    }

	&_background {
        @include absolute-size;
        @include background-cover;
        z-index: 1;

        &:after {
            @include absolute-size;
            content: '';
            opacity: 0.8;
        }
    }

    &_wrapper {
        margin: auto;
        max-width: 1000px;
    	position: relative;
    	z-index: 2;

        @include media($desktop) {
            text-align: center;
        }

        .gfield_label {
            @include font (23, 150, uppercase);
            font-style: normal;

            @include media($desktop) {
                margin-bottom: 8px;
                text-align: left;
            }
        }

        .gform_body {
            @include media($desktop) {
                display: block;
                float: left;
                width: calc(100% - 175px);
            }
        }

        .gfield {
            @include media($desktop) {
                float: left;
                margin-right: 20px;
                width: calc(50% - 20px);
            }
        }

        .gform_footer {
            @include media($desktop) {
                display: block;
                float: left;
                padding-top: 40px;
                width: 175px;
            }
        }

        .gform_fields {
            &:after {
                content: '';
                display: block;
                clear: both;
            }
        }

        .gform_fields,
        .gform_wrapper {
            form {
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
                @include media($desktop) {}
            }
        }
    }
}
