///////////////////////////////////////////////////
//
// Staff
//
///////////////////////////////////////////////////

.moduleWrapper .staffGrid {
    @include clearfix;
    width: 100%;

    &_hero {
        width: 16.667%;
        float: left;
        position: relative;

        @include media(new-breakpoint(min-width 700px max-width 1200px, 8)) {
            width: 25%;
        }

        &_background {
            &:after {
                display: block;
                content: '';
                padding-bottom: 100%;
            }
        }

        &_content {
            @include absolute-size;
            
            &_inner{
                width: 100%;
                height: 100%;
                padding: 0 30px;
                display: table;

                @include media($mobile-only) {
                    padding: 0 10px;
                } 

                @include media($tablet) {
                    padding: 0 20px;
                }
                
                span {
                    display: table-cell;
                    vertical-align: middle;

                    @include media($mobile-only) {
                        font-size: 13px;
                    } 

                    @include media($tablet-only) {
                        font-size: 16px;
                    } 

                    @include media($desktop-only) {
                        font-size: 18px;
                    } 
                    
                    @media screen and (min-width:1200px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .staffGrid_hero_inside {
        display: none;
        width: 0;

        @include media(new-breakpoint(max-width 699px, 8)) {
            display: block;
            width: 50%;
        }

        .staffGrid_hero {
            width: 100%;
        }
    }

    .staffGrid_hero_outside {
        @include media(new-breakpoint(max-width 699px, 8)) {
            display: none;
        }
    }

    &_wrapper {
        display: flex;
        flex-flow: row wrap;
        width: 83.333%;
        float: left;
        @include media(new-breakpoint(min-width 700px max-width 1200px, 8)) {
            width: 75%;
        }

        @include media(new-breakpoint(max-width 699px, 8)) {
            width: 100%;
        }

        &_single {
            width: 20%;
            margin: 0;
            position: relative;
            float: left;
            @include media(new-breakpoint(min-width 700px max-width 1200px, 8)) {
                width: 33.333%;
                &:nth-child(3n) {
                    width: 33.334%;
                }
            }

            @include media(new-breakpoint(max-width 699px, 8)) {
                width: 50%;
            }

            &_header {
                position: relative;

                &:after {
                    display: block;
                    content: '';
                    padding-bottom: 100%;
                }

                &_image {
                    @include absolute-size;
                    @include background-cover;
                }
            }

            &_content {
                padding: 20px 10px 40px 0;

                @include media(new-breakpoint(max-width 699px, 8)) {
                    padding-left: 10px;
                }

                &_title,
                p {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
