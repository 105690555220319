///////////////////////////////////////////////////
//
// MAP
//
///////////////////////////////////////////////////

.map {
    background: #efefef; // Placeholder
    height: 400px;
}

.mapInfoBox {
    position: absolute;
    width: 340px;

    &:after, &:before {
        transform: translateY(-50%) scaleX(0.3) scaleY(0.37);
        @include triangle-sprite;
        content: '';
        position: absolute;
        left: -60px;
        top: 50%;
    }

    &:after {
        background-position: top right;
        z-index: 3;
    }

    &:before {
        background-position: top left;
        text-shadow: 0 0 25px rgba(0, 0, 0, 0.35);
        z-index: 1;
    }    

    &_wrapper {
        background: $white;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.35);
        padding: 30px;
        position: relative;
        z-index: 2;

        &_close {
            background: $white;
            position: absolute;
            height: 17px;
            right: 10px;
            top: 15px;
            width: 17px;

            &:after, &:before {
                background: $black;
                content: '';
                display: block;
                height: 2px;
                
                position: absolute;
                top: 0;
                width: 17px;
            }

            &:after {
                transform: rotate(45deg);
                left: -1px;
            }

            &:before {
                transform: rotate(-45deg);
                left: 0;
            }

            span {
                display: none;
            }
        }

        h5 {
            margin-bottom: 10px;
        }

        p {
            color: $gray;
            margin-bottom: 10px;
        }
    }
}
