///////////////////////////////////////////////////
//
// PAGINATION
//
///////////////////////////////////////////////////

.pagination {
    width: 100%;

    @include media($mobile-only) {
        padding: 0 30px;
    }

    @include media($portables) {
        padding: 0 50px;
    }

    @include media($desktop) {
        padding: 0 60px 0 100px;
    }

    &_list {
        margin: 30px auto;
        text-align: center;
        width: 100%;
        @include clearfix;

        @include media($mobile-only) {
            width: 100%;
        }

        &_single {
            display: inline-block;
            width: 33.3%;
            float: left;
            min-height: 1px;

            @include media($desktop) {
                width: 33.3%;
                float: left;
            }
            
            a {
                text-decoration: none;
                font-style: normal;
            }

            &_current,
            &_dots,
            &_page {
                margin: 0 2px;
                position: relative;
            }

            &_current {
                &:before {
                    border-radius: 50%;
                    content: '';
                    position: absolute;
                    z-index: -1;

                    @include media($mobile-only) {
                        @include size(20px);
                        top: -1px;
                        left: -5px;
                    }

                    @include media($portables) {
                        @include size(25px);
                        top: 0px;
                        left: -6px;
                    }

                    @include media($desktop) {
                        @include size(28px);
                        left: -7px;
                        top: 0px;
                    }
                }
            }

            &-prev {
                text-align: left;

                span {
                    @include media($portables) {
                        display: none;
                    }
                }
            }

            &-pages {
                text-align: center;
            }

            &-next {
                text-align: right;
            }
        }
    }

    &-news {
        @include media($desktop-1400) {
            padding-left: calc((100vw - 1200px)/2);
            width: calc((100vw - 1200px)/2 + 857px);
        }
    }

    &_list_single_current:before {
        @include media($desktop-1400) {
            left: -6px;
            top: 1px;
        }
    }
}