// *************************************
//
//   Base
//   -> Base-level tags (body, h, p, etc.)
//
// *************************************

$Icons: "Icons", sans-serif;

$base-font-size: 16px;
$base-line-height: 24px;

html {
	font-size: $base-font-size;
	line-height: $base-line-height;
}

body {
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// size, tracking, transform
@mixin font ($size, $ls, $trans) {
	text-transform: $trans;
	letter-spacing: $ls * .001em;

	font-size: $size * .65px;
	line-height: $size * .65px  + 10px;

	@include media($tablet) {
		font-size: $size * .80px;
		line-height: $size * .80px + 10px;
	}

	@include media($desktop) {
		font-size: $size * .85px;
		line-height: $size * .85px + 10px;
	} 

	@include media($huge) {
		font-size: $size * 1px;
		line-height: $size + 10px;
	} 
}

@mixin head-font ($size, $ls, $trans) {
	text-transform: $trans;
	letter-spacing: $ls * .001em;

	font-size: $size * .55px;
	line-height: $size * .55px  + 10px;

	@include media($tablet) {
		font-size: $size * .70px;
		line-height: $size * .70px + 10px;
	}

	@include media($desktop) {
		font-size: $size * .85px;
		line-height: $size * .85px + 10px;
	} 

	@include media($huge) {
		font-size: $size * 1px;
		line-height: $size + 10px;
	} 
}

@mixin paragraph-font ($size, $ls, $trans) {
	text-transform: $trans;
	letter-spacing: $ls * .001em;

	font-size: $size * .75px;
	line-height: $size * .75px + 10px;;

	@include media($tablet) {
		font-size: $size * .85px;
		line-height: $size * .85px + 10px;
	}

	@include media($desktop) {
		font-size: $size * .9px;
		line-height: $size * .9px + 10px;
	} 

	@include media($huge) {
		font-size: $size * 1px;
		line-height: $size * 1px + 10px;
	} 
}

@mixin default-spacing () {
	margin-bottom: 10px;

	@include media($tablet) {
		margin-bottom: 15px;
	}

	@include media($desktop) {
		margin-bottom: 10px;
	} 

	@include media($huge) {
		margin-bottom: 25px;
	} 
}

@mixin wrapper {
	margin: auto;
	max-width: 792px;
}


// -------------------------------------
//   Text Sizes
// -------------------------------------

h1, h2, h3, h4, h5, h6, p, blockquote, ul, ol {
	@include default-spacing ();
}

// size, tracking, transform

h1, .h1 {
	@include head-font (60, 90, none);
	font-variant-ligatures: no-common-ligatures;
	-webkit-font-variant-ligatures: no-common-ligatures;
}

h2, .h2 {
	@include head-font (40, 150, uppercase);
}

h3, .h3 {
	@include font (30, 160, uppercase);
}

h4, .h4 {
	@include font (25, 150, none);
}

h5, .h5 {
	@include font (23, 150, uppercase);
}

h6, .h6, {
	@include paragraph-font (17, 150, uppercase);
}


blockquote, .blockquote {
	p {
		@include font (25, 50, none);
		font-style: italic;
	}
}

span.pullQuote {
	@include font (25, 50, none);
	font-style: italic;
}

p, ol, ul, li {
	@include paragraph-font (18, 40, none);

	&.big {
		@include font (25, 40, none);
	}
}

// -------------------------------------
//	Links
// -------------------------------------

a {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}	 

ol, ul {
	list-style-position: inside;
}

b {
	font-weight: bold;
}


// -------------------------------------
//	Typographic Helpers
// -------------------------------------

.condense{
	font-size: 85%;
}

.lead{
	font-size: 125%;
}

.shout{
	text-transform: none;
}

.lowercase {
	text-transform:none!important;
}




body.type-guide {
	.color {
		display: inline-block;
		padding: 30px;
		margin: 0;
		width: 14.285714286%;
		float: left;
		text-align: center;

	// 	&.red {
	// 		// background-color: $red;
	// 	}

	// 	&.white {
	// 		background-color: $white;
	// 	}

	// 	&.black {
	// 		background-color: $black;
	// 		color: $white;
	// 	}

	// 	&.grey1 {
	// 		background-color: $grey1;
	// 	}

	// 	&.grey2 {
	// 		background-color: $grey2;
	// 	}

	// 	&.grey3 {
	// 		background-color: $grey3;
	// 	}

	// 	&.grey4 {
	// 		background-color: $grey4;
	// 	}
	}

	p.guide-icons {
		font-family: $Icons;
		font-size: 30px;
		// color: $grey3;
	}
}