// *************************************
//
//  Helpers
//  -> Extends, Functions, Mixins, Animations, Grid Classes
//
//  --- Edit the following on this sheet: --- //
//
// *************************************

// -------------------------------------
//  Extends
// -------------------------------------


// -------------------------------------
//  Functions
// -------------------------------------
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function add-rem-units($stripped-number) {
  @return $stripped-number * (1rem);
}

// --- REM-erize All The Things --- //
// Specify rems in px amounts from comps

$base-font-size: 16px;
$rem: (1 / strip-units($base-font-size));

@mixin num($element, $amount){
//  @include rem($element, add-rem-units($rem)*$amount);
}


// -------------------------------------
//  Mixins
// -------------------------------------

// Structural Mixins
@mixin absolute-size {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

// Corresponding Class

@mixin img-glide {
  max-width: 100%;
  height: auto;
}


@mixin img-fill {
  min-width: 100%;
  min-height: 100%;
}

//Different Aligns
@mixin center-horiz() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin center-vertically() {
  display: table-cell;
  vertical-align: middle;
}

// A quick n dirty responsive image fallback
img {
  display: block;
  max-width: 100%;
  height: auto;
}

// Need a quick width and height in rems?
@mixin wh-sizing($width, $height) {
  @include num(width, $width);
  @include num(height, $height);
}

// Placeholder forms
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// Stylistic Mixins
@mixin background-cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin background($bgcolor, $img:$bgcolor, $bgposition:$bgcolor) {
  background-color: $bgcolor;
  background-image: $img;
  background-position: $bgposition;
}

// Triangles
@mixin triangle($size, $point, $color, $position: before) {

  @if $position != "before" { // test whether position was overridden
    $position : after;        // if so, ensure it's a valid pseudoclass
  }

  &:#{$position} {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    // specific triangle styles for menu icon
    position: absolute;
    // end specific styles

    @if $point == "up" {
      border-left-width: $size;
      border-left-color: transparent;

      border-right-width: $size;
      border-right-color: transparent;

      border-bottom-width: $size;
      border-bottom-color: $color;

      border-top: none;
    }

    @if $point == "right" {
      border-top-width: $size;
      border-top-color: transparent;

      border-bottom-width: $size;
      border-bottom-color: transparent;

      border-left-width: $size;
      border-left-color: $color;

      border-right: none;
    }

    @else if $point == "down" {
      border-left-width: $size;
      border-left-color: transparent;

      border-right-width: $size;
      border-right-color: transparent;

      border-top-width: $size;
      border-top-color: $color;

      border-bottom: none;
    }
    @else if $point == "left" {
      border-top-width: $size;
      border-top-color: transparent;

      border-bottom-width: $size;
      border-bottom-color: transparent;

      border-right-width: $size;
      border-right-color: $color;

      border-left: none;
    }

    // Skews
    @if $point == "up-skew" {
      border-left-width: ($size * 0.6);
      border-left-color: transparent;

      border-right-width: ($size * 0.6);
      border-right-color: transparent;

      border-bottom-width: $size;
      border-bottom-color: $color;

      border-top: none;
    }

    @if $point == "right-skew" {
      border-top-width: ($size * 0.6);
      border-top-color: transparent;

      border-bottom-width: ($size * 0.6);
      border-bottom-color: transparent;

      border-left-width: $size;
      border-left-color: $color;

      border-right: none;
    }

    @else if $point == "down-skew" {
      border-left-width: ($size * 0.6);
      border-left-color: transparent;

      border-right-width: ($size * 0.6);
      border-right-color: transparent;

      border-top-width: $size;
      border-top-color: $color;

      border-bottom: none;
    }
    @else if $point == "left-skew" {
      border-top-width: ($size * 0.6);
      border-top-color: transparent;

      border-bottom-width: ($size * 0.6);
      border-bottom-color: transparent;

      border-right-width: $size;
      border-right-color: $color;

      border-left: none;
    }
  }
}

// As an example:
// color: shift($gray, 5);
//// will darken $gray 5%

// color: shift($gray, -5);
//// will lighten gray 5%

@function shift($color, $amount){
  @if $amount < 0 {
    $amount : $amount * -1;
    $new-color : lighten($color, $amount);
  } @else {
    $new-color: darken($color, $amount);
  }

  @return $new-color;
}



// -------------------------------------
//  Animations
// -------------------------------------



// Use this custom width mixin if you need to
// break out of the traditional Susy grid

@mixin custom_width($width, $float, $margin-right) {
  @include rem(width, $width);
  float:$float;
  @include rem(margin-right, $margin-right);
}

