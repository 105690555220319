///////////////////////////////////////////////////
//
// LATEST NEWS
//
///////////////////////////////////////////////////

.moduleWrapper .latestNews {
    @include clearfix;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;

    &_hero {
        padding: 90px 5.5% 80px 100px;
        width: 34.5%;
        float: left;
        position: relative;

        @include media($mobile-only) {
            padding: 20px 10px;
        }

        @include media($portables) {
            width: 100%;
            padding: 30px 20px;
        }

        @include media($desktop-1400) {
            width: calc((100% - 1200px)/2 + 414px);
            padding: 90px 77px 80px calc((100% - 1200px)/2);
        }

        &:before {
            content: '';
            @include absolute-size;
            opacity: 0.9;
        }

        &_inner{
            position: relative;

            &_subtitle {
                border-top: 1px solid;
                padding-top: 10px;
                @include font (25, 50, none);
                font-style: italic;
                margin-bottom: 15px;
            }

            &_link {
                @include media($desktop) {
                    margin-top: 20px;
                }
            }
        }
    }

    &_list {
        width: 65.5%;
        float: right;

        @include media($portables) {
            width: 100%;
        }

        @include media($desktop) {
            position: relative;
        }

        @include media($desktop-1400) {
            width: calc((100% - 1200px)/2 + 786px);
        }

        &_item {
            position: relative;
            padding: 55px 100px 70px 8.4%;

            @include media($mobile-only) {
                padding: 20px 10px;
            }

            @include media($portables) {
                padding: 30px 20px;
            }

            @include media($desktop) {
                height: 50%;
                position: absolute;
                width: 100%;
            }

            @include media($desktop-1400) {
                padding: 55px calc((100vw - 1200px)/2) 70px 77px;
            }

            &:last-of-type {
                .latestNews_list_item_link_image:after {
                    opacity: 0.6;
                    transition: opacity 0.2s;
                }

                .latestNews_list_item_content_wrapper {
                    @include text-shadow0;
                }
            }

            &:nth-child(2) {
                @include media($desktop) {
                    top: 50%;
                }
            }

            &:hover {
                .latestNews_list_item_link_image {
                    &:after {
                        opacity: 0.95;
                    }
                }

                &:last-of-type {
                    .latestNews_list_item_link_image {
                        &:after {
                            opacity: 0.70;
                        }
                    }
                }
            } 

            &_link {
                @include absolute-size;
                @include background-cover;
                position: static;
                text-decoration: none;

                &_image {
                    @include absolute-size;
                    @include background-cover;

                    &:after {
                        content: '';
                        @include absolute-size;
                        opacity: 0.85;
                        transition: opacity 0.2s;
                    }
                }

                &_content {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: table;

                    &_wrapper {
                        position: relative;
                        display: table-cell;
                        vertical-align: middle;

                        &_excerpt {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}