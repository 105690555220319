// *************************************
//
//   Variables
//   Edit the information below to establish 
//   base variables througout your document.
//
// *************************************

// -------------------------------------
//   Color Defaults: All colors can be
//   modified/added to in the themes section
// -------------------------------------

// ----- 1. WYSIWYG Colors ----- //

$red: #C40000;
$gray: #5F5F5F;
$white: #FFFFFF;
$black: #2B2B2B;
$yellow: #fef471;
$blue: #63ace2;
$awa-blue: #1A3164;
$awa-grey: #E9ECF0;

// ----- 2. Default Site Context Colors ----- //

$primary : $awa-blue!default; // red
$secondary : $gray!default; // black
$tertiary : $yellow!default; // yellow

$alert : #c4251b!default; // red
$success : #2f9646!default; // green
$warning : #f49224!default; // yellow


// ----- 3. Other Colors (If Applicable) ----- //

$gray-light: #F8F8F8;
$gray-offset: #ebebeb;
$gray-medium: #c4c4c4;
$gray-divider: #EAEBEC;

@mixin text-shadow0 {
	text-shadow: 0px 0px 8px rgba(0,0,0, 0.7);
}
@mixin box-shadow0 {
	box-shadow: 0px 0px 8px rgba(0,0,0, 0.7);
}
@mixin box-shadow1 {
	box-shadow: 0px 0px 18px rgba(0,0,0, 0.7);
}