///////////////////////////////////////////////////
//
// HERO
//
///////////////////////////////////////////////////

.heroSlider {
    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    &_single {
        /* Arrows */
        .slick-prev,
        .slick-next {
            z-index: 1;

            @include media($portables) {
                display: none!important;
            }
        }

        .slick-prev {
            left: 15px;
            transform: translate(0, -50%) rotate(90deg);
            width: 40px;
            height: 40px;
            &:before {
                font-size: 40px;
                @include icon($icon-arrow-down);
            }
        }

        .slick-next {
            right: 15px;
            transform: translate(0, -50%) rotate(-90deg);
            width: 40px;
            height: 40px;

            &:before {
                font-size: 40px;
                @include icon($icon-arrow-down);
            }
        }

        .slick-dots {
            display: none!important;

            @include media($portables) {
                display: block !important;
                bottom: 5px;
            }

            li {
                button {
                    &:before {
                        @include media($portables) {
                            opacity: 0.5;
                            @include text-shadow0;
                        }
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            @include media($portables) {
                                opacity: 1;
                                @include text-shadow0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.hero {
    padding: 40px 10px;
    position: relative;

    @include media($tablet) {
        padding: 40px 20px;
    }

    @include media($desktop) {
        padding: 130px 100px 110px 100px;
    }

    @include media($huge) {
        padding: 150px 100px 130px 100px;
    }

    &_background {
        @include absolute-size;
        @include background-cover;

        &:after {
            @include absolute-size;
            content: '';
            opacity: 0.3;
        }
    }

    &_wrapper {
        margin: auto;
        max-width: 1200px;
        position: relative;
        @include text-shadow0;

        &_title {
            @include media($desktop) {
                max-width: 1000px;
            }
        }

        &_subtitle {
            @include media($desktop) {
                max-width: 1000px;
            }
        }

        &_links {
            text-align: left;

            @include media($tablet) {
                @include clearfix;
            }

            @include media($desktop) {
                margin-top: 50px;
            }
            
            .button {
                @include box-shadow0;
                margin: 0 20px 10px 0;

                @include media($tablet) {
                    float: left;
                    margin: 0 20px 10px 0;
                }

                &:last-child {
                    margin-bottom: 0;

                    @include media($tablet) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
