///////////////////////////////////////////////////
//
// BUTTONS
//
///////////////////////////////////////////////////

.button {
    transition: all 0.25s;

    @include font (20, 150, uppercase);
    line-height: 100% !important;
    padding: 16px 36px;
    border-radius: 90px;
    color: white;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-width: 2px;
    border-style: solid;

    @include media($portables) {
        padding: 13px 30px;
    }

    @include media($mobile-only) {
        padding: 11px 26px;
    }

    &.primary {
        z-index: 1;
        border: none;

        &:after {
            content: ' ';
            transition: all 0.25s;
            @include font (20, 150, uppercase);
            line-height: 100% !important;
            padding: 17px 37px;
            border-radius: 90px;
            color: white;
            text-decoration: none;
            cursor: pointer;
            display: inline-block;
            position: relative;
            //border-width: 2px;
            border: none;
            border-style: solid;
            background-color: $black;
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            border-color: $black;
            opacity: 0;
            z-index: -1;

            @include media($portables) {
                padding: 13px 30px;
            }

            @include media($mobile-only) {
                padding: 11px 26px;
            }
        }

        &:hover {
            &:after {
                opacity: 0.2;
            }
        }
    }
}

.button.primary, .button.secondary {
    text-shadow: none;
}

