///////////////////////////////////////////////////
//
// MAIN MENU
//
///////////////////////////////////////////////////

.headerMenu {
    transition: opacity 0.2s;
    list-style: none;
    padding-top: 60px;
    position: fixed;

    @include media($portables) {
        left: 0;
        opacity: 0;
        overflow-y: auto;
        top: 40px;
        bottom: 0;
        width: 100%;
        z-index: -98;
    }

    @include media($desktop) {
        @include clearfix;
        padding-top: 0;
        top: 40px;
        right: 20px;
        z-index: 200;
    }

    @include media($huge) {
        right: calc((100% - 1400px) / 2);
        top: 60px;
    }

    a {
        display: block;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;

        @include media($portables) {
            line-height: 50px;
        }

        @include media($desktop) {
            font-size: 12px;
        }

        @include media($huge) {
            font-size: 16px;
        }
    }

    &_single {
        position: relative;

        @include media($desktop) {
            display: inline-block;
            float: left;
        }

        &_link {
            transition: all 0.35s ease-in-out;
            position: relative;
            z-index: 2;

            @include media($portables) {
                padding: 0 15px;
            }

            @include media($desktop) {
                display: inline-block;
                line-height: 50px;
                padding: 5px 20px;
            }

            @include media($huge) {
                padding: 20px 26px;
            }

            &:hover {
                transition: all 0.35s ease-in-out;
            }
        
            &:after {
                @include icon($icon-arrow-down);
                display: none;
                position: absolute;
                transition: all 0.3s;
                transform-origin: center 40%;

                @include media($portables) {
                    right: 20px;
                    top: 17px;
                }

                @include media($desktop) {
                    transform: translateX(-50%);
                    font-size: 10px;
                    left: 50%;
                    top: 40px;
                }

                @include media($huge) {
                    transform: translateY(2px);
                    font-size: 16px;
                    position: static;
                    padding-left: 15px;
                }

                .headerMenu_single-menu & {
                    display: inline-block;
                }
            }

            &:first-child {
                @include media($huge) {
                    padding-top: 30px;
                }
            }

            &.headerMenu_single_link-active:after {
                transform: rotate(180deg);
                @include media($desktop) {
                    transform: rotate(0deg);
                }
            }
        }

        &:nth-child(even) {
            background: transparentize($black, 0.9);
            @include media($desktop) {
                background:none;
            }
        }

        &-menu {
            cursor: default;
        }

        &_menu {
            transition: all 0.35s ease-in-out;
            z-index: 1;

            @include media($portables) {
                max-height: 0;
                overflow-y: hidden;
            }

            @include media($desktop) {
                box-shadow: 0 0 20px 10px transparentize($black, 0.7);
                //left: -20px;
                left: 0px;
                opacity: 0;
                position: absolute;
                pointer-events: none;
                top: 60px;
                width: 270px;
            }

            @include media($huge) {
                top: 100px;
            }

            &:after {
                @include media($desktop) {
                    content: '';
                    display: block;
                    height: 25px;
                    left: -20px;
                    opacity: 1;
                    pointer-events: none;
                    position: absolute;
                    top: -25px;
                    width: calc(100% + 40px);
                    z-index: 202;
                }
            }

            &_link {
                @include media($portables) {
                    border-top: 1px solid transparentize($black, 0.9);
                    padding: 0 15px 0 45px;
                }

                @include media($desktop) {
                    line-height: 35px;
                    padding: 5px 20px;
                    display: none;
                    transition: background 0.3s;
                }

                &:nth-child(even) {
                    @include media($desktop) {
                        // background: transparentize($black, 0.9);
                    }
                }

                &:nth-child(odd) {
                    @include media($desktop) {
                        padding: 5px 20px;
                    }
                }

                &:first-child {
                    @include media($desktop) {
                        padding-top: 15px;
                    }
                }

                &:hover {
                    @include media($desktop) {
                        background: transparentize($black, 0.7);
                    }
                }
            }

            &-active {
                @include media($portables) {
                    max-height: 3000px;
                }

                @include media($desktop) {
                    opacity: 1;
                    pointer-events: all;
                }

                a {
                    @include media($desktop) {
                        display: block;
                    }
                }
            }
        }
    }

    .body-headerActive & {
        @include media($portables) {
            opacity: 1;
            pointer-events: all;
            z-index: 102;
        }
    }
}
