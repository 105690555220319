///////////////////////////////////////////////////
//
// HEADER TOGGLE
//
///////////////////////////////////////////////////

.headerToggle {
    transition: all 0.2s;
    // +option: background = color_background_primary
    left: 0;
    position: fixed;
    top: 40px;
    width: 100%;
    z-index: 103;

    @include media($desktop) {
        top: 40px;
    }

    @include media($huge) {
        height: 100px;
        padding: 0 20px;
        top: 60px;
    }

    @media screen and (max-width: 1439px) {
        height: 60px;
    }

    &_container {
        @include media($desktop) {
            height: 100%;
            margin: auto;
            max-width: 1400px;
            position: relative;
        }

        &:after {
            @include media($desktop) {
                transition: all 0.5s;
                transform: translateY(-150px);
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
                content: '';
                position: fixed;
                right: 102px;
                top: 0;
                height: 75px;
                width: 165px;
                z-index: -1;
            }
        }

        &_logo {
            transform: translateY(-50%);
            height: 60%;
            left: 15px;
            position: absolute;
            top: 50%;

            @include media($huge) {
                height: 60px;
                left: 0;
            }
        }

        &_toggle {
            display: inline-block;
            height: 50px;
            position: absolute;
            right: 7px;
            top: 6px;
            width: 50px;
            z-index: 103;

            @include media($desktop) {
                display: none;
            }

            &:hover {
                .headerToggle_toggle_bars {
                    &:after {
                        transform: translateY(-2px);
                    }

                    &:before {
                        transform: translateY(2px);
                    }
                }
            }

            &_bars,
            &_bars:after,
            &_bars:before {
                background-color: $black;
                height: 4px;
                width: 25px;
            }

            &_bars {
                transform: translateY(-50%);
                display: block;
                left: 0;
                position: relative;
                margin: 0 auto;
                top: 50%;

                &:after,
                &:before {
                    transition: transform 0.2s;
                    content: '';
                    display: block;
                    position: absolute;
                }

                &:after {
                    margin-top: 8px;
                }

                &:before {
                    margin-top: -8px;
                }
            }
        }
    }

    .body-headerActive & {
        box-shadow: none;
        pointer-events: all;

        .headerToggle_container {

            &_toggle {

                &_bars, &:hover .headerToggle_toggle_bars {
                    background-color: transparent;

                    &:before, &:after {
                        // +option: background = color_primary
                        margin-top: 0;
                        transition-delay: 0, 0.2s;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    .body-modalActive & {
        opacity: 0;
    }
}
