///////////////////////////////////////////////////
//
// WYSIWYG
//
///////////////////////////////////////////////////

.moduleWrapper .wysiwyg {
    padding: 20px 20px 0 20px;
    @include media($mobile-only) {
        padding: 30px 10px;
    }
    @include media($tablet) {
        @include clearfix;
        padding: 40px 20px;
    }
    @include media($desktop) { 
        padding: 50px 130px;
    }
    &_wrapper {
        margin: auto;
        max-width: 900px;
        @include media($desktop) { 
            padding: 0;
        }

        .alignnone, .aligncenter, .alignleft, .alignright {
            padding: 10px 0;
            @include media($mobile-only) {
                display: block;
                width: 100%;
                margin: 15px 0;
            }
        }

        .alignnone, .aligncenter {
            margin: auto;
            width: auto;
            @include media($tablet) {
                padding: 25px 0;
            }
            @include media($desktop) {
                width: calc(100% + 60px);
                max-width: none;
                padding: 30px 0;
                left: -30px;
                position: relative;
            }
        }

        .alignleft, .alignright {
            @include media($tablet) {
                display: inline-block;
                max-width: 490px;
                width: 50%;
            }
        }

        .alignleft {
            @include media($tablet) {
                float: left;
                margin: 5px 20px 5px 0;
            }
            @include media($desktop) {
                width: 300px;
                transform: translateX(-90px);
                margin: 0 -60px 0 0;
                padding: 30px 0;
            }
        }

        .alignright {
            @include media($tablet) {
                float: right;
                margin: 5px 0 5px 20px;
            }
            @include media($desktop) {
                width: 300px;
                transform: translateX(90px);
                margin: 0 0 0 -60px;
                padding: 30px 0;
            }
        }

        hr {
            margin: 30px 0;
            position: relative;
            @include media($desktop) { 
                width: calc(100% + 120px);
                left: -60px;
                margin: 40px 0;
            }
        }

        ul {
            @include media($tablet) {
                padding-left: 20px;
            }
            li {
                @include media($tablet) {
                    list-style-position: outside;
                }
            }
        }
    }

    @include media($mobile-only) {
        padding: 30px 10px;

        .alignleft, .alignright, .alignnone, .aligncenter {
            display: block;
            width: 100%;
            margin: 15px 0;
        }
    }

    @include media($tablet) {
        @include clearfix;
        padding: 40px 20px;

        &_wrapper {
            .alignleft, .alignright {
                display: inline-block;
                max-width: 490px;
                width: 50%;
            }

            .alignnone, .aligncenter {
                padding: 25px 0;
            }
            .alignleft {
                float: left;
                margin: 5px 20px 5px 0;
            }
            .alignright {
                float: right;
                margin: 5px 0 5px 20px;
            }

            ul {
                padding-left: 20px;

                li {
                    list-style-position: outside;
                }
            }
        }
    }

    @include media($desktop) { 
        padding: 50px 130px;

        &_wrapper {
            padding:  0;

            .alignleft {
                width: 300px;
                transform: translateX(-90px);
                margin: 0 -60px 0 0;
                padding: 30px 0;
            }

            .alignright {
                width: 300px;
                transform: translateX(90px);
                margin: 0 0 0 -60px;
                padding: 30px 0;
            }

            .alignnone, .aligncenter {
                width: calc(100% + 60px);
                max-width: none;
                padding: 30px 0;
                left: -30px;
                position: relative;
            }

            hr {
                width: calc(100% + 120px);
                left: -60px;
                margin: 40px 0;
            }
        }
    }
}