///////////////////////////////////////////////////
//
// QUOTE
//
///////////////////////////////////////////////////

.moduleWrapper .quote {
	position: relative;
    @include clearfix;

	&_container {
        @include clearfix;
        max-width: 1400px;
        margin: auto;
        &_wrapper {
	        display: block;
	        margin: 0 auto;
	        padding: 58px 100px;
	        position: relative;
	        @include clearfix;

	        @include media($mobile-only) {
				width: 100%;
                padding: 30px 20px;
	        }

	        @include media($tablet-only) {
                width: 100%;
                padding: 40px 20px;
	        }

	        &_text {
	        	quotes: "“" "”";
				&:before {
					content: open-quote;
				}
				&:after  {
					content: close-quote;
				}
	        }
	        &_attribution {
	        	float: left;
				font-style: normal;
				text-transform: uppercase;
	        	img {
	        		width: 55px;
	        		height: 55px;
	        		border-radius: 50%;
	        		vertical-align: middle;
	        		display: inline-block;
	        		margin-right: 10px;
	        		@include media($mobile-only) {
                        display: block;
                        width: 40px;
                        height: 40px;
                        margin-bottom: 6px;
	        		}

	        		@include media($tablet-only) {
                        width: 49px;
                        height: 49px;
	        		}
	        	}
	        }
	    }
    }
}