///////////////////////////////////////////////////
//
// IMAGE GRID
//
///////////////////////////////////////////////////

.moduleWrapper .imageGrid {
	@include clearfix;
	margin: 0 auto;

	&_single {
		width: 25%;
		float: left;
		height: 25vw;
		overflow: hidden;
		position: relative;
		@include clearfix;

		@include media($portables) {
            width: 50%;
            height: 50vw;
            max-height: none;
		}

		&_imageWrap {
			@include absolute-size;
        	@include background-cover;
		}

		&_content {
			@include absolute-size;

			@include media($portables) {
				padding: 0 10px 10px 10px;
			}

			&:after {
				transition: all, 0.25s;
				@include absolute-size;
	        	@include background-cover;
				content: " ";
				opacity: 0.3;
			}

			h4, h6 {
				@include text-shadow0;
				z-index: 2;
				position: absolute;
				margin-bottom: 0px;
				left: 20px;
				right: 20px;
				bottom: 40px;

				@include media($huge) {
					left: 30px;
					right: 30px;
				} 
			}

			h4 {
				transition: bottom 0.3s;

				@include media($mobile-only) {
					font-size: 14px;
					line-height: 20px;
				}

				@include media($portables) {
                    left: 15px;
                    right: 15px;
                    bottom: 20px;
				}
			}

			h6 {
				bottom: 0;
				opacity: 0;
				transition: all 0.3s;

				&:after {
					@include icon($icon-arrow-right);
					position: relative;
					top: 2px;
					left: 6px;
					font-weight: bold;
				}
			}

			&:hover {
				h4 {
					bottom: 70px;
				}

				h6 {
					opacity: 1;
					bottom: 30px;
				}
			}
		}

		&-link {
			.imageGrid_single_imageWrap {
				transition: transform 0.3s;
			}

			&:hover {
				.imageGrid_single_imageWrap {
					transform: scale(1.1);
				}
			}
		}
	}
}