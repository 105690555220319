///////////////////////////////////////////////////
//
// IMAGE ALTERNATOR
//
///////////////////////////////////////////////////

.moduleWrapper .imageAlternator {
	&_wrapper {
		margin: 0 auto;
		@include clearfix;
		position: relative;
		overflow:hidden;

		&_background {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 50%;
			@include background-cover;

			@include media($portables) {
                position: relative;
                max-width: none;
                height: 50vw;
                width: 100%;
                @include background-cover;
			}
		}

		&_content {
			padding-bottom: 60px;
			padding-top: 60px;
			width: 50%;
			position: relative;

			@include media($mobile-only) {
				padding: 25px 10px;
			}

			@include media($portables) {
                max-width: none;
                margin: 0;
                width: 100%;
                padding: 35px 20px;
			}

			&_subtitle {
				@include font (25, 50, none);
				font-style: italic;
			}

			&_link {
				@include media($desktop) {
					margin-top: 20px;	
				} 
			}

			&_background {
				z-index: -2;
				@include absolute-size;
        		@include background-cover;
				transform: scaleX(-1);
				filter: blur(12px);
			}

			&_background_behind {
				z-index: -3;
				@include absolute-size;
        		@include background-cover;
				transform: scaleX(-1);
			}
		}
	}
}

.imageAlternator-odd {
	.imageAlternator_wrapper_background {
		left: 0;
	}

	.imageAlternator_wrapper_content {
		float: right;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background-color: $gray;
			opacity: 0.3;
		}
		
		&_background:after {
			@include absolute-size;
			content: ' ';
			opacity: 0.8;
		}

		&_background_behind:after {
			@include absolute-size;
			content: ' ';
			opacity: 0.8;
		}

		&_block {
			margin-left: 50px;
			padding-right: 100px;
			float: left;
			max-width: 650px;

			@include media($portables) {
				float: left;
				max-width: none;
				margin: 0;
				padding: 0;
			}
		}
	}
}

.imageAlternator-even {
	.imageAlternator_wrapper_background {
		right: 0;
	}

	.imageAlternator_wrapper_content {
		float: left;

		&_background:after {
			@include absolute-size;
			content: ' ';
			opacity: 0.8;
		}

		&_background_behind:after {
			@include absolute-size;
			content: ' ';
			background: $blue;
			opacity: 0.8;
		}

		&_block {
			margin-right: 50px;
			padding-left: 100px;
			float: right;
			max-width: 650px;

			@include media($portables) {
				max-width: none;
				margin: 0;
				padding: 0;
			}
		}
	}
}