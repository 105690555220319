///////////////////////////////////////////////////
//
// VIDEO
//
///////////////////////////////////////////////////

.moduleWrapper .videoModule {
    position: relative;
    @include clearfix;
    width: 100%;
    display: flex;

    &_background {
        @include absolute-size;
        @include background-cover;

        &:after {
            @include absolute-size;
            content: '';
            opacity: 0.8;
        }
    }

    &_wrapper {
        position: relative;
        width: 100%;
        @include media($portables) {
            display: block;
        }

        &_videoPlayer {
            padding: 70px 60px 70px 100px;
            width: 60.1%;
            float: left;
            position: relative;  
            text-align: center;
            @include media($mobile-only) {
                padding: 20px 10px;
            }
            @include media($portables) {
                width: 100%;
                padding: 30px 20px;
            }

            &_video {
                @include box-shadow1;
                background-color: $black;
            }

            &_embed {
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                padding-top: 25px;
                height: 0;
            }

            &_embed iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &_content {
            width: 39.9%;
            float: right;
            position: relative;
            padding: 100px 100px 70px 0px;
            @include media($mobile-only) {
                padding: 20px 10px;
            }
            @include media($portables) {
                width: 100%;
                padding: 30px 20px;
            }
        }
    }
}

.moduleWrapper {
    .video {
        position: relative;

        &_control {
            @include absolute-size;
            display: block;
            @include triangle(100px, right, white, after);

            &:after {
                transform: translate(-50%, -50%) scale(0.5);
                transition: opacity 0.2s;
                background-position: top left;
                content: '';
                left: 50%;
                position: absolute;
                top: 50%;
                @include media($desktop) {
                    transform: translate(-50%, -50%);
                }

                .video-playing & {
                    opacity: 0;
                }
            }

            span {
                display: none;
            }
        }

        &_player {
            display: block;
            height: auto;
            width: 100%;
        }
    }
}