///////////////////////////////////////////////////
//
// CALL TO ACTION
//
///////////////////////////////////////////////////

.moduleWrapper .cta {
    padding: 20px;
    position: relative;

    @include media($tablet) {
        padding: 40px 20px;
    }

    @include media($desktop) {
        padding: 100px 0;
    }

    &_background {
        @include absolute-size;
        @include background-cover;

        &:after {
            @include absolute-size;
            content: '';
            opacity: 0.4;
        }
    }

    &_wrapper {
        @include text-shadow0;
        position: relative;

        @include media($tablet) {
            text-align: center;
        }

        @include media($desktop) {
            margin: 0 auto;
            padding: 0 70px;
            max-width: 1440px;
        }

        @include media($huge) {
            padding: 0 260px;
        }

        &_links {
            @include media($tablet) {
                @include clearfix;
            }

            @include media($desktop) {
                margin-top: 30px;
            }

            .button {
                @include box-shadow0;
                margin: 0 20px 10px 0;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}
