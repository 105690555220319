///////////////////////////////////////////////////
//
// NEWS DETAIL
//
///////////////////////////////////////////////////

.single-post {
    .main {
        .hero {
            padding-top: 60px;
            padding-bottom: 50px;

            @include media($mobile-only) {
                padding-top: 40px;
                padding-bottom: 30px;
            }

            @include media($portables) {
                padding-top: 50px;
                padding-bottom: 40px;
            }

            &_wrapper {
                &_meta {
                    dd {
                        display: inline-block;
                    }
                    span{
                        margin: 0 10px;
                        &:last-of-type {
                            display: none;
                        }
                    }
                }
            }
        }

        .news_block {
            @include clearfix;

            @include media($tablet) {
                display: flex;
            }

            .single_news_wrapper {
                margin: auto;
                width: 100%;
                float: left;

                @include media($mobile-only) {
                    padding: 30px 10px;
                }

                @include media($tablet) {
                    @include clearfix;
                    margin: 0;
                    width: 67.8%;
                    padding: 40px 20px;
                }

                @include media($desktop) {
                    @include clearfix;
                    margin: 0;
                    width: 67.8%;
                    padding: 55px 20px 55px 0;
                }

                .alignleft, .alignright {
                    padding: 10px 0;

                    @include media($mobile-only) {
                        padding: 5px 0px;
                        display: block;
                        max-width: none;
                        width: 100%;
                    }

                    @include media($tablet) {
                        display: inline-block;
                        max-width: 490px;
                        width: 50%;
                    }
                }

                .aligncenter, .alignnone {
                    @include media($tablet) {
                        margin: 0 auto;
                        padding: 20px 0;
                    }
                }

                .alignleft {
                    @include media($tablet) {
                        float: left;
                        margin: 0 20px 0 0;
                    }

                    @include media($desktop) { 
                        transform: translateX(-90px);
                        margin: 0 -60px 0 0;
                        padding: 30px 0;
                    }
                }

                .align-right {
                    @include media($tablet) {
                        float: right;
                        margin: 0 0 0 20px;
                    }

                    @include media($desktop) { 
                        transform: translateX(90px);
                        margin: 0 0 0 -60px;
                        padding: 30px 0;
                    }
                }

                .aligncenter {
                    margin: 30px auto;

                    @include media($mobile-only) {
                        padding: 10px 0;
                    }
                }

                .alignnone {
                    margin: 30px auto;
                    width: 80%;

                    @include media($mobile-only) {
                        display: block;
                        padding: 10px 0;
                        margin: 0;
                        width: 100%;
                    }
                }

                &-noSidebar {
                    @include media($tablet) {
                        width: 100%;
                    }

                    @include media($desktop) {
                        padding-right: 0;
                        width: 100%;
                    }

                    .single_news_content {
                        @include media($desktop) {
                            padding-right: 140px;
                        }

                        @include media($desktop-1400) { 
                            padding-right: calc((100vw - 1200px)/2 + 40px);
                        }
                    }
                }

                .single_news_content {
                    @include media($desktop) {
                        float: left;
                        padding-right: 85px;
                        padding-left: 140px;
                    }

                    @include media($desktop-1400) { 
                        padding-left: calc((100vw - 1200px)/2 + 40px);
                    }

                    li {
                        padding-left: 20px;
                        list-style-type: none;
                        position: relative;
                        margin-bottom: 10px;

                        &:before {
                            content: '';
                            background-color: $red;
                            border-radius: 50%;
                            width: 7px;
                            height: 7px;
                            position: absolute;
                            top: 8px;
                            left: 0px;
                        }
                    }

                    hr {
                        height: 3px;
                        margin: 30px 0;
                        background: $red;
                        border: none;
                    }
                }
            }

            .navigator {
                border-top: 2px solid $red;
                margin-top: 60px;
                padding-top: 10px;
                clear: both;

                li {
                    list-style-type: none;
                    padding-left: 0px !important;
                    text-transform: uppercase;
                    font-size: 87%;

                    @include media($tablet) {
                        font-size: 75%;
                    }

                    &:before {
                        display: none;
                    }
                }
                
                .navigator_list_single-prev {
                    position: relative;
                    padding-left: 20px !important; 

                    @include media($desktop) {
                        padding-left: 30px !important;
                    } 
                    
                    a {
                        &:after {
                            @include icon($icon-arrow-right);
                            position: absolute;
                            top: 30px;
                            left: 0;
                            font-weight: bold;
                            transform: rotate(180deg);
                        }
                    }
                }

                .navigator_list_single-next {
                    position: relative;
                    padding-right: 20px !important; 

                    @include media($desktop) {
                        padding-right: 30px !important;
                    }

                    a {
                        &:after {
                            @include icon($icon-arrow-right);
                            position: absolute;
                            top: 30px;
                            right: 0;
                            font-weight: bold;
                        }
                    }
                }

                &_list {
                    @include clearfix;
                    margin-bottom: 0;

                    &_single {
                        display: inline-block;
                        padding-top: 20px;

                        a {
                            text-decoration: none;
                            font-style: normal;
                        }

                        &-prev {
                            float: left;
                            width: 33.3%;

                            @include media($mobile-only) {
                                width: 50%;
                            }

                            @include media($portables) {
                                padding-left: 0;
                                font-size: 17px;
                            }

                            span {
                                @include media($portables) {
                                    display: none;
                                }
                            }
                        }

                        &-index {
                            float: left;
                            width: 33.4%;
                            text-align: center;

                            @include media($mobile-only) {
                                display: none;
                            }

                            @include media($portables) {
                                font-size: 17px;
                            }
                        }

                        &-next {
                            float: left;
                            text-align: right;
                            width: 33.3%;

                            @include media($mobile-only) {
                                width: 50%;
                            }

                            @include media($portables) {
                                padding-right: 0;
                                font-size: 17px;
                            }
                        }
                    }
                }
            }

            .sidebar {
                width: 100%;
                float: left;
                overflow: hidden;
                z-index: 2;

                @include media($tablet-only) { 
                    width: 32.2%;
                    min-height: 100%;
                }

                @include media($desktop) { 
                    width: 32.2%;
                    min-height: 100%;
                }

                aside {
                    padding: 60px 0 0 50px;

                    @include media($mobile-only) {
                        padding: 10px;
                    }

                    @include media($tablet-only) { 
                        padding: 20px;
                    }

                    h5 {
                        position: relative;
                        margin-bottom: 15px;
                    }

                    ul {
                        margin-bottom: 45px;
                        list-style: none;

                        @include media($mobile-only) {
                            margin-bottom: 25px;
                        }

                        @include media($tablet-only) {
                            margin-bottom: 35px;
                        }

                        li {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
