///////////////////////////////////////////////////
//
// NAVIGATION MODULES
//
///////////////////////////////////////////////////

.main {
	padding-top: 100px;

	@media screen and (min-width: 1440px) {
		padding-top: 160px;
	}
}

@import "body";
@import "headerMenu";
@import "headerScreen";
@import "headerShadow";
@import "headerToggle";
@import "topBar";
