///////////////////////////////////////////////////
//
// FULL IMAGE SLIDER
//
///////////////////////////////////////////////////

.moduleWrapper .fullImageSlider {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(to right,  rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 15%,rgba(0,0,0,0) 85%,rgba(0,0,0,0.4) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#66000000',GradientType=1 ); /* IE6-9 */
	}

	.slick-dotted.slick-slider {
        margin-bottom: 0;
    }

	&_single {
		/* Arrows */
		.slick-prev,
		.slick-next {
			z-index: 1;

			@include media($portables) {
				display: none!important;
			}
		}

		.slick-prev {
		    left: 15px;
		    transform: translate(0, -50%) rotate(90deg);
		    width: 40px;
		    height: 40px;

		    &:before {
		    	font-size: 40px;
                @include icon($icon-arrow-down);
                opacity: 1;
                text-shadow: 0px 0px 12px rgba(0,0,0,0.6)
            }
		}

		.slick-next {
		    right: 15px;
		    transform: translate(0, -50%) rotate(-90deg);
		    width: 40px;
		    height: 40px;

		    &:before {
		    	font-size: 40px;
                @include icon($icon-arrow-down);
                opacity: 1;
                text-shadow: 0px 0px 12px rgba(0,0,0,0.6)
            }
		}

		.slick-dots {
            display: none!important;

            @include media($portables) {
                display: block !important;
                bottom: 10px;
            }

            li {
            	button {
            		&:before {
            			@include media($portables) {
		                    opacity: 0.5;
		                    @include text-shadow0;
            			}
            		}
            	}

            	&.slick-active {
            		button {
            			&:before {
            				@include media($portables) {
			                    opacity: 1;
			                    @include text-shadow0;
            				}
            			}
            		}
            	}
            }
        }

		&_item {
			position: relative;

			&:after {
				content: " ";
				display: block;
				padding-bottom: 48%;
			}

			&_image {
				@include absolute-size;
				@include background-cover;

				img {
					width: 100%;
				}
			}
		}
	}
}