///////////////////////////////////////////////////
//
// VIDEO
//
///////////////////////////////////////////////////

.video {

    &_player {
        display: block;
        height: auto;
        width: 100%;
    }
}
