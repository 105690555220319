///////////////////////////////////////////////////
//
// LINKS TEXT
//
///////////////////////////////////////////////////

.moduleWrapper .linksText {
    @include clearfix;
    width: 100%;
    display: flex;

    @include media($portables) {
        display: block;
    }

    &_hero {
        padding: 70px 60px 60px 100px;
        width: 32.1%;
        float: left;
        position: relative;

        @include media($mobile-only) {
            padding: 20px 10px;
        }

        @include media($portables) {
            width: 100%;
            padding: 30px 20px;
        }

        @include media($desktop-1400) {
            padding: 70px 60px 60px calc((100% - 1200px)/2);
            width: calc((100vw - 1200px)/2 + 385px);
        }

        &_content {
        	max-width: 230px;

            @include media($portables) {
                max-width: unset;
            }

        	&_title {
        		padding-bottom: 20px;
                margin-bottom: 30px;

                @include media($mobile-only) {
                    padding-bottom: 10px;
                    max-width: 270px;
                }

                @include media($portables) {
                    max-width: 350px;
                }
        	}

        	&_link {
        		&_single a {
        			text-decoration: none;
                    display: inline-block;
                    margin-bottom: 20px;
                    line-height: 1.25;
                    font-size: 90%;
                    padding-right: 26px;
                    position: relative;
                    text-transform: none;
                    display: block;

                    &:after {
                        @include icon($icon-arrow-right-2);
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        font-weight: bold;
                        transform: translate(0, -50%);
                        transition: all 0.3s;
                        opacity: 0;
                    }

                    &:hover {
                        text-decoration: underline;

                        &:after {
                            right: -3px;
                            opacity: 1;
                        }
                    }
        		}
        	}
        }

        .linksText-right & {
            float: right;
            padding: 70px 100px 60px 60px;

            @include media($mobile-only) {
                padding: 20px 10px;
            }

            @include media($portables) {
                padding: 30px 20px;
            }

            @include media($desktop-1400) {
                padding: 70px calc((100% - 1200px)/2) 60px 60px;
            }
        }
    }

    &_content {
        width: 67.9%;
        float: right;
        position: relative;
        padding: 70px 100px 60px 60px;

        @include media($mobile-only) {
            padding: 20px 10px;
        }

        @include media($portables) {
            width: 100%;
            padding: 30px 20px;
        }

        @include media($desktop-1400) {
            padding: 70px calc((100vw - 1200px)/2) 60px 60px;
            width: calc((100vw - 1200px)/2 + 815px);
        }

        .linksText-right & {
            float: right;
            padding: 70px 60px 60px 100px;

            @include media($mobile-only) {
                padding: 20px 10px;
            }

            @include media($portables) {
                padding: 30px 20px;
            }

            @include media($desktop-1400) {
                padding: 70px 60px 60px calc((100vw - 1200px)/2);
            }
        }
    }

    &-right {
        flex-direction: row-reverse;
    }
}
