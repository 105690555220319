///////////////////////////////////////////////////
//
// FORM
//
///////////////////////////////////////////////////


input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea,
select {
    appearance: none;

    border-radius: 0;

    &:focus {
        outline:none;
    }
}


.gform_button {
    // ...
}

.gform_confirmation_wrapper {
    color: $gray;
    margin-bottom: 10px;
}

.gform_fields {
    list-style: none;
}

.gfield_label {
    color: $black;
    display: block;
    font-style: italic;

    .gfield_required {
        display: none;
    }
}

.ginput_container {
    margin-bottom: 10px;

    input[type="email"],
    input[type="number"],
    input[type="text"],
    textarea {
        background: transparent;
        border: 1px solid $black;
        color: $gray;
        display: block;
        line-height: 32px;
        padding: 0 5px;
        width: 100%;

        &:active, &:focus {
            border: 1px solid $gray;
            outline: 0;
        }
    }

    @include media($desktop) {
        margin-bottom: 22px;

        input[type="email"],
        input[type="number"],
        input[type="text"],
        textarea {
            border-width: 2px;
            padding: 9px 15px;

            &:active, &:focus {
                border-width: 2px;
            }
        }
        input[type="email"],
        input[type="number"],
        input[type="text"] {
            height: 54px;
        }
    }
}

.gfield_error {
    background: transparentize($red, 0.85);
    margin-top: 10px;
    padding: 10px;

    .ginput_container {
        margin-bottom: 5px;
    }

    .gfield_label, .validation_message {
        color: $red;
        font-style: italic;
        font-weight: 700;
    }
}

.validation_error {
    color: $red;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 10px;
}

.gform_validation_container {
    display: none;
}

.ginput_container_radio {
    ul.gfield_radio {
        li {
            list-style-type: none;
            list-style-type: none;
            cursor: pointer;
            margin: 15px 0;
            padding-left: 30px;
            position: relative;

            input[type=radio] {
                position: absolute;
                visibility: hidden;
            }
        
            label {
                transition: all 0.3s;
                @include paragraph-font (18, 40, none);
                color: black;

                &:before {
                    border: 2px solid black;
                    content: '';
                    cursor: pointer;
                    display: inline-block;
                    height: 22px;
                    left: 0;
                    position: absolute;
                    top: 2px;
                    width: 22px;
                    border-radius: 100%;
                }


                &:after {
                    transform: scale(0);
                    transition: transform 0.2s;
                    background: $red;
                    content: '';
                    height: 12px;
                    left: 5px;
                    position: absolute;
                    top: 7px;
                    width: 12px;
                    border-radius: 100%;
                }

                &:hover {
                    color: $black;

                    &:before {
                        border: 2px solid black;
                    }
                }
            }
            input:checked + label:after {
                transform: scale(1);
            }

            input:checked + label:before {
                border-color: black;
            }

            input:checked + label {
                color: black;
            }  
        }
    }
}

.ginput_container_textarea + .gfield_description, .ginput_container_text + .gfield_description {
    // margin-top: -10px;
    margin-bottom: 20px;
}

.ginput_container_name {
    label {
        margin-bottom: 10px;
        display: block;
    }
}

@include media($desktop) {
    .ginput_container {
        margin-bottom: 30px;
    }

    .ginput_container_textarea + .gfield_description, .ginput_container_text + .gfield_description {
        // margin-top: -20px;
        margin-bottom: 30px;
    }

    .ginput_container_name {
        label {
            margin-bottom: 10px;
            display: block;
        }
    }
} 


.gfield_checkbox {
    li {
        display: block;
        position: relative;
        padding-left: 30px;
        position: relative;
        margin-bottom: 25px;

        @include media($tablet) {
            margin-bottom: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        label {
            color: $black;
            transition: all 0.15s;

            &:before {
                content: '';
                width: 20px;
                height: 20px;
                border: 2px solid $red;
                background: none;
                position: absolute;
                left: 0px;
            }

            &:after {
                content: '';
                width: 9px;
                height: 22px;
                border-right: 4px solid $red;
                border-bottom: 4px solid $red;
                position: absolute;
                left: 11px;
                transform: rotate(45deg);
                top: -7px;
                opacity: 0;
                transition: all 0.15s;
            }

            &:hover {
                color: $red;

                &:before {
                    border-color: $red;
                    background-color: $red;
                }
            }
        }

        input {
            display: none;
        }

        input[type="checkbox"]:checked + label:after {
            opacity: 1;
        }

        input[type="checkbox"]:checked + label {
            color: $black;
        }

        input[type="checkbox"]:checked + label:before {
            border-color: $black;
            background-color: $white;
        }
    }
}

.ginput_container_select {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    &:after {
        content: 'g';
        font-family: $Icons;
        text-transform: none;
        color: $red;
        top: calc(50% - 14px);
        position: absolute;
        right: 27px;
        font-size: 20px;
        pointer-events: none;
    }

    select {
        border: 1px solid $black;
        border-radius: none;
        background: none;
        //@include paragraph-font (16, 25, 400, 120, uppercase);
        width: 100%;
        height: 35px;
        padding: 0 15px;


        @include media($desktop) {
            height: 55px;
            padding: 0 55px 0 15px;
        } 
    }
}

.gfield {
    @include clear;
}

.gfield_time_hour {
    width: 30%;
    margin-right: 5%;
    float: left;

    i {
        display: none;
    }
}

.gfield_time_minute {
    width: 30%;
    margin-right: 5%;
    float: left;

    i {
        display: none;
    }
}

.gfield_time_ampm {
    width: 30%;
    float: left;

    position: relative;
    //width: 100%;
    margin-bottom: 20px;

    &:after {
        content: 'g';
        font-family: $Icons;
        text-transform: none;
        color: $red;
        top: calc(50% - 10px);
        position: absolute;
        right: 20px;
        font-size: 20px;
        pointer-events: none;
    }

    select {
        border: 1px solid $black;
        border-radius: none;
        background: none;
        //@include paragraph-font (16, 25, 400, 120, uppercase);
        width: 100%;
        height: 35px;
        padding: 0 15px;


        @include media($desktop) {
            height: 50px;
            padding: 0 55px 0 15px;
        } 
    }
}

h2.gsection_title {
    @include font (30, 160, uppercase);
    display: block;
    padding-bottom: 10px;
    border-bottom: 1px solid $black;
    color: $red;
    margin-top: 40px;
}

.gform_body {
    .gfield {
        &:first-child {
            .gsection_title {
                margin-top: 0;
            }
        }
    }
}


// Gravity Forms hidden label when class is applied to the form fields while editing form //
.hide-labels {
    div span label {
        visibility: hidden;
    }
}

.ui-datepicker { 
    width: 17em; 
    padding: .2em .2em 0; 
    display: none;
    background-color: $white;
    border: 1px solid #999999;

    .ui-datepicker-header { 
        position:relative; 
        padding:.2em 0; 
        border:0px;
        font-weight: bold;
        width: 100%;
        padding: 4px 0;
    }
    .ui-datepicker-prev,
    .ui-datepicker-next { 
        position:absolute; 
        top: 2px; 
        width: 1.8em; 
        height: 1.8em; 
    }

    .ui-datepicker-prev-hover,
    .ui-datepicker-next-hover { 
        /*top: 1px;*/ 
    }
    .ui-datepicker-prev { left:2px; }
    .ui-datepicker-next { right:2px; }

    .ui-datepicker-prev-hover { /*left:1px;*/ }
    .ui-datepicker-next-hover { /*right:1px;*/ }

    .ui-datepicker-prev span, 
    .ui-datepicker-next span { 
        display: block;
        font-size: 11px;
        margin-top: -7px;
        position: absolute;
        top: 50%;
        &:hover {
            cursor: pointer;
        }
    }
    .ui-datepicker-title {
        margin: 0 2.3em;
        line-height: 1.8em;
        text-align: center;
        select { 
            font-size:1em; 
            margin:1px 0; 
        }
    }
    select.ui-datepicker-month-year {
        width: 100%;
    }
    select.ui-datepicker-month, select.ui-datepicker-year {
        background: none;
        border: none;
        width: 25%;
    }
    table {
        width: 100%; 
        font-size: .9em; 
        border-collapse: collapse; 
        margin:0 0 .4em; 
    }
    th { 
        padding: .7em .3em; 
        text-align: center; 
        font-weight: bold; 
        border: 0;  
    }
    td {
        border: 0; 
        padding: 1px; 
        span, a { 
            display: block; 
            padding: .2em; 
            text-align: right; 
            text-decoration: none; 
        }
    }
    .ui-datepicker-buttonpane { 
        background-image: none; 
        margin: .7em 0 0 0; 
        padding:0 .2em; 
        border-left: 0; 
        border-right: 0; 
        border-bottom: 0; 
        button { 
            float: right; 
            margin: .5em .2em .4em; 
            cursor: pointer; 
            padding: .2em .6em .3em .6em; 
            width:auto; 
            overflow:visible;
            .ui-datepicker-current {
                float:left; 
            }
        }
    }
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi { width:auto; }
.ui-datepicker-multi .ui-datepicker-group { float:left; }
.ui-datepicker-multi .ui-datepicker-group table { 
    width:95%; 
    margin:0 auto .4em; 
}
.ui-datepicker-multi-2 .ui-datepicker-group { width:50%; }
.ui-datepicker-multi-3 .ui-datepicker-group { width:33.3%; }
.ui-datepicker-multi-4 .ui-datepicker-group { width:25%; }
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header { border-left-width:0; }
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width:0; }
.ui-datepicker-multi .ui-datepicker-buttonpane { clear:left; }
.ui-datepicker-row-break { 
    clear:both; 
    width:100%; 
    font-size:0em; 
}

/* RTL support */
.ui-datepicker-rtl {
    direction: rtl;
    .ui-datepicker-prev { 
        right: 2px; 
        left: auto;
        &:hover {
            right: 1px; 
            left: auto; 
        }
    }
    .ui-datepicker-next { 
        left: 2px; 
        right: auto;
        &:hover {
            left: 1px; 
            right: auto;
        }
    }
    .ui-datepicker-buttonpane {
        clear:right; 
        button {
            float: left;
            .ui-datepicker-current {
                float:right;
            }
        }
    }
    .ui-datepicker-group {
        float:right;
    }
    .ui-datepicker-group-last .ui-datepicker-header { 
        border-right-width:0; 
        border-left-width:1px; 
    }
    .ui-datepicker-group-middle .ui-datepicker-header { 
        border-right-width:0; 
        border-left-width:1px; 
    }
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
    display: none; /*sorry for IE5*/
    //display/**/: block; /*sorry for IE5*/
    position: absolute; /*must have*/
    z-index: -1; /*must have*/
    filter: mask(); /*must have*/
    top: -4px; /*must have*/
    left: -4px; /*must have*/
    width: 200px; /*must have*/
    height: 200px; /*must have*/
}

.ui-datepicker th {
    font-weight: bold;
    color: gray;
}

.ui-datepicker-today {
    a {
        background-color: $black;
        cursor: pointer;
        padding: 0 4px;
        margin-bottom:0px;
    }
}


.ui-datepicker td {
    a {
        margin-bottom:0px;
        border:0px;
    }

    .ui-state-default {
        border: 0;
        background:none;
        margin-bottom: 0;
        padding: 5px;
        color: gray;
        text-align: center;
        filter: none;
        &:hover {
            background-color: #999;
            border-radius: 4px;
            color: #ffffff;
        }
    }
    
    .ui-state-highlight {
        color: $white;
        background: $black;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        border-color: #eedc94 #eedc94 #e4c652;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        border-radius: 4px;

    }
    
    .ui-state-active {
        margin-bottom:0px;
        font-size:normal;
        text-shadow: 0px;
        border-radius: 4px;

    }
}


form.post-password-form{
    padding: 20px 20px 0 20px;

    margin: auto;
    max-width: 900px;

    @include media($mobile-only) {
        padding: 30px 10px;
    }

    @include media($tablet) {
        padding: 40px 20px;
    }

    @include media($desktop) { 
        padding: 60px 0px;
    }

    input[type="password"] {
        padding-left: 0.5em;
        padding-right: 0.5em;
        border: 2px solid;
        line-height: 32px;
        height: 54px;
    }

    input[type="submit"] {
        transition: all 0.25s;

        @include font (20, 150, uppercase);
        line-height: 100% !important;
        padding: 16px 36px;
        border-radius: 90px;
        color: white;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        position: relative;
        border-width: 2px;
        border-style: solid;
        margin: 0 20px;

        @include media($portables) {
            padding: 13px 30px;
        }

        @include media($mobile-only) {
            padding: 11px 26px;
            display: block;
            margin: 20px 0;
        }
    }
}

