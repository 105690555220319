// -------------------------------------
// Breakpoints
// -------------------------------------

$max-width: 60rem; // 960 / 16
$tablet-width: 500px;
$desktop-width: 960px;
$huge-width: 1440px;


// Breakpoints
$tablet: new-breakpoint(min-width $tablet-width 8); // (max-width #columns) 
$desktop: new-breakpoint(min-width $desktop-width 12); // (max-width #columns) 
$huge: new-breakpoint(min-width $huge-width 12); // (max-width #columns) 

$mobile-only: new-breakpoint(max-width ($tablet-width - 1px), 8);
$tablet-only: new-breakpoint(min-width $tablet-width max-width ($desktop-width - 1px), 8);
$portables: new-breakpoint(max-width ($desktop-width - 1px), 8);
$desktop-only: new-breakpoint(min-width $desktop-width max-width ($huge-width - 1px), 8);

$visual-grid-color: lighten(#FFF0E8, 3%);

$desktop-1400: new-breakpoint(min-width 1400px 12);
$desktop-1440: new-breakpoint(min-width 1440px 12);

$visual-grid: true;
$visual-grid-color: #E6F6FF;
$visual-grid-opacity: 0.4;
