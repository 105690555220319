///////////////////////////////////////////////////
//
// NEWS LANDING
//
///////////////////////////////////////////////////

.blog, .archive .main {
    position: relative;

    .news_block {
        @include clearfix;
        z-index: 0;
        overflow: hidden;
        display: flex;

        @include media($mobile-only) {
            display: block;
        }

        &:before {
            @include media($desktop) {
                content: '';
                z-index: -3;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &_content {
            padding: 30px 0 0 0;
            float: left;
            display: inline-block;
            width: 68%;
            position: relative;

            @include media($mobile-only) {
                padding-top: 0;
                width: 100%;
            }

            @include media($portables) {
                padding-top: 0;
            }

            @include media(new-breakpoint(max-width 760px, 8)) {
                padding-top: 0;
            }

            @include media($desktop-1400) {
                width: calc((100vw - 1200px)/2 + 857px);
            }

            .news_content {
                @include clearfix;
                padding: 30px 0;
                padding-left: 100px;
                padding-right: 60px;

                @include media($mobile-only) {
                    padding: 15px 10px;
                }

                @include media($portables) {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                @include media(new-breakpoint(max-width 760px, 8)) {
                    padding: 20px;
                }

                @include media($desktop-1400) {
                    width: calc((100vw - 1200px)/2 + 857px);
                    padding-left: calc((100vw - 1200px)/2);
                }

                .img_container {
                    float: left;
                    clear: left;
                    display: inline-block;
                    width: 320px;
                    height: 240px;
                    @include background-cover;

                    @include media($portables) {
                        width: 256px;
                        height: 192px;
                    }

                    @include media(new-breakpoint(max-width 760px, 8)) {
                        display: none;
                    }

                    @include media(new-breakpoint(min-width $desktop-width max-width 999px, 12)) {
                        width: 224px;
                        height: 168px;
                    }

                    @include media(new-breakpoint(min-width 1000px max-width 1200px, 12)) {
                        width: 256px;
                        height: 192px;
                    }
                }

                .text_container {
                    float: left;
                    width: calc(100% - 320px);
                    padding-left: 40px; 

                    @include media($mobile-only) {
                        width: 100%;
                        padding: 10px 0 0 0;
                    }

                    @include media($portables) {
                        width: calc(100% - 256px);
                    }

                    @include media(new-breakpoint(max-width 760px, 8)) {
                        width: 100%;
                        padding: 20px 0 0 0;
                    }

                    @include media(new-breakpoint(min-width $desktop-width max-width 999px, 12)) {
                        width: calc(100% - 224px);
                    }

                    @include media(new-breakpoint(min-width 1000px max-width 1200px, 12)) {
                        width: calc(100% - 256px);
                    }

                    h5 {
                        @include media($mobile-only) {
                            margin-bottom: 3px;
                        }
                    }

                    p {
                        @include media($mobile-only) {
                            margin-bottom: 8px;
                        }
                    }
                }
            }

            &-noSidebar {
                width: 100%;

                @include media($desktop-1400) {
                    padding-left: 100px;
                    padding-right: 100px;
                    width: 100%;
                }

                .news_content {
                    padding-right: 100px;

                    @include media($desktop-1400) {
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 1200px;
                        padding-left: 0;
                        padding-right: 0;
                        width: 100%;
                    }
                }
            }
        }

        &_sidebar {
            overflow: hidden;
            float: right;
            padding-right: 100px;
            display: inline-block;
            width: 32%;

            @include media($mobile-only) {
                padding: 0 10px;
                width: 100%;
                position: relative;
            }

            @include media($portables) {
                padding: 0px 20px;
            }

            @include media($desktop-1400) {
                width: calc((100vw - 1200px)/2 + 343px);
                padding-right: calc((100vw - 1200px)/2);
            }

            aside {
                padding: 60px 0 0 60px;

                @include media($mobile-only) {
                    padding-top: 10px;
                }

                @include media($portables) {
                    padding-left: 0;
                    padding-top: 30px;
                }

                h5 {
                    position: relative;
                    margin-bottom: 15px;

                    @include media($portables) {
                        margin-bottom: 5px;
                    }
                }

                ul {
                    margin-bottom: 45px;
                    list-style: none;

                    @include media($mobile-only) {
                        margin-bottom: 25px;
                    }

                    @include media($portables) {
                        margin-bottom: 35px;
                    }

                    li {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
