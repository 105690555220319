///////////////////////////////////////////////////
//
// BODY
//
///////////////////////////////////////////////////

.body {
    &-headerActive, &-modalActive {
        overflow: hidden;
        pointer-events: none;
    }
}
