///////////////////////////////////////////////////
//
// TITLE
//
///////////////////////////////////////////////////

.moduleWrapper .title {
	.title_wrapper {
		margin: 0 auto;
		padding: 60px 100px 30px 100px;
		max-width: 1400;
		text-align: center;
		@include media($mobile-only) {
			padding: 20px 10px 10px 10px;
		}
		@include media($portables) {
			padding: 30px 20px 10px 20px;
		}
		@include media($desktop) {
			max-width: 1200px;
			margin: 0 auto;
		} 

		&_text {
			margin-bottom: 0;
		}
	}
}