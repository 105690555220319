///////////////////////////////////////////////////
//
// HEADER SHADOW
//
///////////////////////////////////////////////////

.headerShadow {
    //display: none;
    background: transparent;
    background-image: linear-gradient(to bottom, transparentize($black, 0.8), transparent);
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 201;
    height: 10px;
    top: 100px;

    @include media($tablet) {
        height: 10px;
        top: 100px;
    }

    @include media($desktop) {
        top: 100px;
    } 

    @include media($huge) {
        height: 20px;
        top: 160px;
    }
}
