///////////////////////////////////////////////////
//
// IMAGE
//
///////////////////////////////////////////////////

.moduleWrapper .image {
    max-width: none;
    width: 100%;
}
